<template>
  <div class="container mt-3">
    <div class="d-flex align-items-start">
      <p-table :data="state.employees" :columns="employeesColumns" placeholder="-">
        <template v-slot:col-action="props" class="text-center">
          <span class="material-icons fs-5 pointer" @click="state.employeeToRemove = props.row">cancel</span>
        </template>
      </p-table>

      <div class="ms-5 w-40">
        <p-icon-button class="btn-outline-dark mb-3" text="Добавить сотрудника" icon="add"
                       @click.prevent="requestCode"/>
      </div>
    </div>

  </div>
  <p-invite-employee :show="state.showCode" @close="state.showCode = false" :code="state.code"/>
  <p-confirmation-modal
    v-if="state.employeeToRemove != null"
    :error="state.removeEmployeeError"
    @accept="removeEmployee"
    @cancel="state.employeeToRemove = null"
  >
    <template v-slot:title>
      Удаление сотрудника
    </template>
    <template v-slot:confirmation-text>
      Вы уверены, что хотите удалить сотрудника <b>{{ state.employeeToRemove.lastName }}
      {{ state.employeeToRemove.firstName }} {{ state.employeeToRemove.middleName }}</b>
      из <b>{{ store.state.company.name }}</b>?
    </template>
  </p-confirmation-modal>

</template>

<script setup>
import api from '@/api'
import urls from '@/api/urls'
import PConfirmationModal from '@/components/modals/ConfirmationModal'
import PInviteEmployee from '@/components/modals/InviteEmployee'
import PTable from '@/components/ui/Table'
import { onMounted, reactive } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const state = reactive({
  showCode: false,
  employeeToRemove: null,
  code: null,
  copied: false,
  employees: [],
  removeEmployeeError: null,
})

const employeesColumns = [
  {
    name: 'Фамилия',
    key: 'lastName',
  },
  {
    name: 'Имя',
    key: 'firstName',
  },
  {
    name: 'Отчество',
    key: 'middleName',
  },
  {
    name: 'Номер телефона',
    key: 'phone',
  },
  {
    name: 'Email',
    key: 'email',
  },
  {
    name: 'Удалить',
    key: 'action',
  },
]

const requestCode = async () => {
  state.showCode = true
  const { code } = await api.get(urls.COMPANY.ISSUE_CODE)
  state.code = code
}

const load = async () => {
  try {
    state.employees = await api.get(urls.COMPANY.EMPLOYEES)
  } catch (e) {

  }
}

const removeEmployee = async () => {
  state.removeEmployeeError = null
  try {
    await api.get(urls.COMPANY.REMOVE_EMPLOYEE, {
      params: {
        employee_id: state.employeeToRemove.id,
      },
    })
    state.employees = state.employees.filter(v => v.id !== state.employeeToRemove.id)
    state.employeeToRemove = null
  } catch (err) {
    state.removeEmployeeError = err.message
  }
}

onMounted(load)
</script>

<style scoped>
.w-40 {
  width: 40% !important;
}
</style>
