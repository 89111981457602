<template>
  <p-base-modal :show="show" @close="$emit('close')">
    <template v-slot:title>
      Добавление нового сотрудника
    </template>
    <template v-slot:content>
      <div class="w-100 d-flex justify-content-center align-items-center" v-if="code">
        <span class="code">{{ code }}</span>
        <div class="pointer ttp" @click="copyCode">
          <i class="material-icons">content_copy</i>
          <span class="tooltiptext">
            <span v-if="state.copied">Скопировано</span>
            <span v-else>Скопировать</span>
          </span>
        </div>
      </div>
      <div v-else class="w-100 d-flex justify-content-center pb-3">
        <div class="spinner-border spinner-border-md" role="status"></div>
      </div>

      <div class="text-center">
        <p>
          Сообщите этот код сотруднику, он сможет ввести его в мобильном приложении и получать заявки от
          {{ store.state.company && store.state.company.name ? store.state.company.name : 'вашей организации' }}
        </p>
        <p>
          Код одноразовый и может быть использован только одним сотрудником в течение 30 дней
        </p>
      </div>
    </template>
  </p-base-modal>
</template>

<script setup>
import { defineProps, reactive } from 'vue'
import { useStore } from 'vuex'
import PBaseModal from './Base'

const store = useStore()

const props = defineProps({
  show: {
    type: Boolean,
    required: true,
  },
  code: null,
})

const state = reactive({
  copied: false,
})

const copyCode = () => {
  navigator.clipboard.writeText(props.code)
  state.copied = true
  setTimeout(() => {
    state.copied = false
  }, 3000)
}
</script>

<style scoped>
.code {
  padding: 7px 12px;
  font-weight: bolder;
  letter-spacing: 0.1em;
}
</style>
